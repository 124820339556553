import { ReactNode } from 'react'
import { LazyMotion } from 'framer-motion'
import React from 'react'

import { loadFeatures } from '../../lib/framer-motion'

type LayoutProps = {
  children: ReactNode
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className="h-full bg-amber-50">
      <LazyMotion features={loadFeatures} strict>
        {children}
      </LazyMotion>
    </div>
  )
}

export default Layout
